<!--
 * @Author       : Hugo
 * @Date         : 2021-03-31 14:53:26
 * @LastEditTime: 2022-05-19 20:40:00
 * @LastEditors: hugo 1634765756@qq.com
 * @FilePath     : /miaohang/src/components/radar/growingData.vue
 * @Description  : 雷达图[能力成长数据]
 * @^_^          : 
-->
<template>
  <div
    class="growing_data_group cp"
    ref="growing_data_group"
    @click.stop="isVip()"
  ></div>
</template>
<script>
import tools from '@/api/tools'
import * as echarts from 'echarts';
export default {
  props: {
    userId: {
      type: Number,
      default: 0,
    }
  },
  computed:{
    vip(){
      const {user} = this
      if(user === undefined){
        return false
      }
      return user.userVip
    },
    user_data_update(){
      return this.$store.state.user_data_update;
    },
  },
  watch: {
    userId: {
      handler: async function(){
        this.initGrowingData();
      }
    },
    user_data_update: function(val){
      if(val){
        const user = this.$tools.getCookie('user')
        this.$set(this, 'user', user)
        this.initGrowingData()
      }
    }
  },
  data(){
    return {
      user: undefined,
      option: {
         // title: {
        //     text: '基础雷达图'
        // },
        tooltip: {
          show:false,
        },
        legend: {
            data: ['我的能力成长数据'],
            // show: true,
        },
        radar: {
            // shape: 'circle',
            name: {
                textStyle: {
                    color: '#fff',
                    backgroundColor: '#999',
                    borderRadius: 3,
                    padding: [3, 5]
                }
            },
            indicator: [
                { name: '团队参与活跃指数', max: 6500},
                { name: '管理（Administration）', max: 16000},
                { name: '信息技术（Information Techology）', max: 30000},
                { name: '客服（Customer Support）', max: 38000},
                { name: '研发（Development）', max: 52000},
                { name: '市场（Marketing）', max: 25000}
            ],
        },
        series: [{
            name: '能力成长数据',
            type: 'radar',
            // areaStyle: {normal: {}},
            data: [
                {
                    value: [4300, 10000, 28000, 35000, 50000, 19000],
                    name: '我的能力成长数据'
                },
                {
                    value: [5000, 14000, 28000, 31000, 42000, 21000],
                    name: '能力成长平均值'
                }
            ],
            symbolOffset: [0, 100],
            label: {
              show: false,
            }
        }]
      },
      myChat: undefined,
    }
  },
  methods: {
     /**
     * @description: 获取成长数据
     * @param {*}
     * @return {*}
     */
    async getGrowingData(){
      let res;
      if(!this.userId){
        return {};
      }
      try{
        res = await this.$http.request('/project/personalExponent', {userId: this.userId});
        if(res.message == 'success'){
          return res;
        }
      }catch(err){
        // console.log(err);
      }
      return {};
    },
    /**
     * @description: 初始化成长数据
     * @param {*}
     * @return {*}
     */
    async initGrowingData(){
      const {vip} = this
      let res = await this.getGrowingData();
      let data = res.contents.PPersonalExponent || {};
      const chartDom = this.$refs.growing_data_group;
      var myChat = echarts.init(chartDom);
      this.option.series[0].label.show = false
      this.$set(this, 'myChat', myChat)
      // var option;
      // option = {
      //   // title: {
      //   //     text: '基础雷达图'
      //   // },
      //   tooltip: {
      //     show:false,
      //   },
      //   legend: {
      //       data: ['我的能力成长数据'],
      //       // show: true,
      //   },
      //   radar: {
      //       // shape: 'circle',
      //       name: {
      //           textStyle: {
      //               color: '#fff',
      //               backgroundColor: '#999',
      //               borderRadius: 3,
      //               padding: [3, 5]
      //           }
      //       },
      //       indicator: [
      //           { name: '团队参与活跃指数', max: 6500},
      //           { name: '管理（Administration）', max: 16000},
      //           { name: '信息技术（Information Techology）', max: 30000},
      //           { name: '客服（Customer Support）', max: 38000},
      //           { name: '研发（Development）', max: 52000},
      //           { name: '市场（Marketing）', max: 25000}
      //       ],
      //   },
      //   series: [{
      //       name: '能力成长数据',
      //       type: 'radar',
      //       // areaStyle: {normal: {}},
      //       data: [
      //           {
      //               value: [4300, 10000, 28000, 35000, 50000, 19000],
      //               name: '我的能力成长数据'
      //           },
      //           {
      //               value: [5000, 14000, 28000, 31000, 42000, 21000],
      //               name: '能力成长平均值'
      //           }
      //       ],
      //       symbolOffset: [0, 100],
      //       label: {
      //         show: Boolean(vip),
      //       }
      //   }]
      // };
      this.option.radar.indicator = this.$api.radar.getRadarIndcator(data);
      this.option.series[0].data = this.$api.radar.getSeriesData(data);
      this.option && myChat.setOption(this.option);
    },
    isVip(){
      const membership_privileges = this.$api.user.membershipPrivileges();
      if(!membership_privileges){
        return ;
      }
      this.option.series[0].label.show=true
      this.myChat.setOption(this.option)
    },
  },
  async mounted(){
    this.$set(this, 'user', tools.getCookie('user'))
    this.initGrowingData();
  }
}
</script>

<style lang="scss" scoped>
.growing_data_group{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  // width: 100%;
  width: 600px;
  height: 100%;
}
</style>